import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import { micromark } from "micromark";

import Layout from "../components/layout";
import ApplicationProcessDiagram from "../components/application-process-diagram";

const NewProposal = (props) => {
  const data = props.data.allMarkdownRemark.nodes[0].frontmatter;
  const { title, subtitle, main, help } = data[props.pageContext.language];

  const mainHTML = micromark(main);
  const helpHTML = micromark(help);

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5">
            <h1 className="block-title">{title}</h1>
            <h2 className="anchor-title">{subtitle}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: mainHTML }}
              className="mb-3"
            ></div>

            <div className="mb-3">
              <Link to={"/form-wizard"} className="btn btn-secondary btn-lg">
                <FormattedMessage
                  id="get_started"
                  defaultMessage="Get Started"
                />
              </Link>
            </div>
            <div dangerouslySetInnerHTML={{ __html: helpHTML }}></div>
          </div>
          <div className="col-12 col-md-7">
            <h4 className="text-center pb-5">
              <FormattedMessage
                id="project_lifecycle_title"
                defaultMessage="The Lifecyle of a Performance Project"
              />
            </h4>
            <ApplicationProcessDiagram
              expanded={false}
            ></ApplicationProcessDiagram>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fields: { slug: { eq: "/new-proposal/" } } }) {
      nodes {
        frontmatter {
          title
          en {
            slug
            title
            subtitle
            main
            help
          }
          cy {
            slug
            title
            subtitle
            main
            help
          }
        }
        id
        fields {
          slug
        }
      }
    }
  }
`;

export default NewProposal;
